<template>
    <div class="login-wrap">
        <div class="header flex-all-center">
            <slot name="header">
                <div>{{ basic.title }}</div>
            </slot>
        </div>
        <div class="footer flex-all-center">
            <slot name="footer">
                <section>
                    <div class="font-12 text-center">
                        <span>{{ basic.title }} Copyright 2023 ~ {{ dayjs().year() }} All rights reserved</span>
                    </div>
                    <div class="font-12 text-center">
                        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">京ICP备19018855号-1</a>
                    </div>
                </section>
            </slot>
        </div>
        <div class="content flex">
            <slot name="content" />
        </div>
    </div>
</template>

<script setup>
import pageinfo from '~~/config/pageinfo';
import { useUserStore } from '~~/store/user';
const userStore = useUserStore();
const route = useRoute();
useHead({
    title: pageinfo[route.name]?.title
});
const basic = reactive({
    title: userStore.platform_title,
});
</script>

<style lang="scss" scoped>
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(2,48,142), rgb(2,37,120), rgb(4,31,106));

    .header {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 121px;
        font-size: 44px;
        font-weight: bolder;
        color: #ffffff;
        letter-spacing: 10px;
    }

    .footer {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        color: #ffffff;
        width: 100%;
        height: 103px;
        background-size: 100% 100%;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: transparent url('/images/background.png') center top no-repeat;
        background-size: 100% auto;

        z-index: 1;
    }
}</style>